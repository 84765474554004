body {
  display: flex;
  justify-content: center;
  align-content: center;
}

body ::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
}
